.App {
  text-align: center;

  z-index: 1;
}

@font-face {
  font-family: 'DecovarAlpha';
  src: url('./fonts/DecovarAlpha-VF.ttf');
  font-display: swap;
}

@font-face {
	font-family:'Decovar Regular24';
	src: url('./fonts/Decovar-VF.ttf') ;
  font-display: swap;
}

.container {
  font-family: "Decovar Regular24", "Helvetica", "Arial", sans-serif;
  font-variation-settings: "SKLA" 1000, "TRMG" 750;
  width: 75%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#testvariable {
  margin: 0;
  font-size: 10vw;
  position: relative;
  font-weight: 400;
  font-family: "DecovarAlpha","Helvetica", "Arial", sans-serif;
  font-variation-settings: 'SKLD' 0;

  text-shadow: 5px 5px 5px red;
  animation: grow 8s linear 1;

  	&:before {
		text-shadow:
			1px 1px 2px red,
			-1px 1px 2px greenyellow,
			-1px -1px 2px blue,
			1px -1px 2px purple,
			3px 3px 20px orange;
	}

	&:after {
		color: #421F00;
		font-variation-settings: 'INLN' 0;
	}

}


@keyframes grow {
	0% {
		font-variation-settings:  'SKLD' 1000;
	}

	50% {
		font-variation-settings:  'SKLD' 500;
	}
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  height: 550px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

i,
span {
  font-size: 50px;
}

.mainicons {
  font-size: 8vw;
}

.icondiv {
  height: 30%;
  background: orange;
  border-radius: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
}

#blackbox {
  color: white;
  padding: 50px;
  font-weight: 800;
  margin: 20px
}

.blackitems {
  font-size: 30px;
}

#introtext {
      overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
    font-family: Tiempos,Georgia,serif;
    line-height: 1.1;
    font-weight: 600;
    font-size: 20pt;

}

#introtext2 {
  word-wrap: break-word;
    font-family: Tiempos,Georgia,serif;
    line-height: 22px;
    margin: 2%;

}

/*Carousel.Item {*/
/*height: 400px;*/
/*background-size: cover;*/
/*background-position: 50% 50%;*/
/*width: 100%;*/
/*height: 100%;*/

/*}*/

/*.carousel-inner {*/
/*!* make sure your .items will get correct height *!*/
/*height: 100%;*/
/*}*/

.carousel-item img {
  width: 100%;
}

/*.carouselbox {*/
/*height: 400px;*/
/*}*/

.carouselbox {
  padding: 20px;
  margin: auto;
  overflow: hidden;
}

.gridcontainer {
  display: grid;
  grid-gap: 20px;
  height: 100vh;
  grid-template-columns: 4fr;
  height: max-content;
  /*grid-auto-rows: auto; !* overflow size *!*/
  /*grid-auto-flow: row; !* overflow type *!*/
}

.gridheight {
  height: 100vh;
}

.grid-item {
  overflow: hidden;
}

.grid-item:nth-of-type(2) {
  grid-row-start: span 2;
}

.grid-item:nth-of-type(6) {
  grid-column: 3 / span 2;
}

.gridimage {
  width: 100%;
  height: auto;
}

@media (min-width: 900px) {
  .gridcontainer {
    margin: 10px;
    grid-template-rows: [abc] auto;
    grid-template-columns:
      [yo] 20%
      [mo] 60%
      [zo] 1fr;
  }
}

.bottombox {
  background-color: black;
}

.footer {
  grid-column: 1 / span 3;
  color: white;
  height: auto;
  text-align: center;
}

.mysubgrid {
  grid-template-rows: [abc] auto;
  grid-template-columns:
    [yo] 20%
    [mo] 60%
    [zo] 20%;
}

@media (min-width: 700px) {
  .mysubgrid {
    grid-template-columns:
      [ho] auto
      [jo] auto
      [lo] auto
      [zo] auto;
    grid-template-rows: auto;
    display: grid;
    font-size: 20px;
    font-family: fantasy;
    padding: 80px;
  }
}

.affiliategrid {
  /*position: absolute;*/
  display: grid;
  grid-gap: 10px;
  /*height: 100%;*/
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  background-color: white;
  /*grid-auto-rows: auto; !* overflow size *!*/
  /*grid-auto-flow: row; !* overflow type *!*/
}

#accessorygrid {
  display: grid;
  grid-gap: 10px;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  background-color: white;
  flex-wrap: wrap;
}

#accessorygrid a img {
  width: 15vw;
}

.myaffimagewalmartsegway {
  width: 15vw;
}

.myparallax {
  position: relative;
}

.react-parallax {
  height: 300px;
  margin-bottom: 10px;
}

.emailform {
  width: 60%;
}

#video {
  z-index: 0;
  position: relative;
  height: auto;
}

#bannertext {
  font-size: 40pt;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 40px;
  color: darkorange;
  width: 100%;
  text-align: -webkit-center;
  margin-top: 7%;
}

#overlay2 {
  z-index: 1;

  width: 100%;
  background-color: white;

  padding-top: 20px;
  color: darkorange;
}

#magazinegrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: lightsteelblue;
}

#magazinegrid ul {
  list-style-type: none;
}

#parallaximage {
  position: fixed;
  height: auto;
  width: 1000px;
  z-index: -1;
  left: 22%;
  top: 20px;
}

.pagination-bottom span {
  font-size: 20pt;
}

.carouselimage {
  height: 500px;
}
#editorschoice {
  width: 10vh;
  position: absolute;
  height: auto;

}



@media (min-width: 700px) and (max-width:1100px) {

  #accessorygrid {
    /*position: absolute;*/
    display: grid;
    grid-gap: 10px;

    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    background-color: white;
  }
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#icondivholder {
  background-color: white;
}

#reccomendations {
  padding: 40px;
}

ul {
  padding-left: 0px;
}

.myaffimage {
  width: 250px;
  height: 250px;
  overflow: hidden;
}

.linklist {
  background-color: lightsteelblue;
  margin-bottom: 35px;
}

.linklist a {
  color: black;
}

.rt-thead {
  background-color: lightblue;
}

#citiesheader {
  background-color: lightsteelblue;
}

#calloutimg {
  z-index: 1;
  position: absolute;
  width: 20%;
  right: 100px;
  top: -77px;
}

#tableholder {
  position: relative;
}

.otherclass {
  display: none;
}

.socialicons {
  font-size: 15px;
}

ul#iconslist {
  list-style: none;
}

#iconslist li {
  display: inline;
}

#detailpagegrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

#prosconstable {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5%;
}

.TipCaution p {
  padding: 20px 20px 10px 10px;
}

.blog-post-content p:nth-child(1) {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.blog-post-content span {
  width: 100%;
  height:auto;
}

.ml13 {
background: rgb(63,94,251);
background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(99,89,224,1) 6%, rgba(122,86,206,1) 15%, rgba(212,75,137,1) 42%, rgba(127,85,202,1) 46%, rgba(142,83,190,1) 73%, rgba(252,70,107,1) 100%);
  z-index: 10000;
  position: absolute;
 align-items: center;
  width:100%;
  height: 100%;
  font-size: 1.9em;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-weight: 600;
}

.ml13 .letter {
  display: inline-block;
  line-height: 1em;
  color: darkorange;
}

.gatsby-resp-image-image {
  height: auto;
}

.gridcontainer {
      font-size: 1em;
}

#sectionpills {
  background-color: lightblue;
  padding: 15px;
}

#pagetwoheader {
  width: 100vw;
  height: 15vh;
  background-color: orange;
      font-size: 2em;
    color: white;
    font-weight: 400;
    font-family: 'DecovarAlpha';
}

#warningtext {
  font-size: 1em;
    padding: 20px;
    text-align: center;
    color: palevioletred;
    padding-top: 30px;
}

.tabpanel {
  width: 100%;
  height: max-content;
}

.blog-post p {
  display: grid;
  font-size: 1.5em;
}

.markdownimage {
      width: 50%;
      height: auto;

}

#pricetablegrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 20px;
}

#scooterbrandslist {
  list-style-type: none;
}

#container {
    margin-left: 20vw;
    margin-right: 20vw;
  font-family: "Decovar Regular24", "Helvetica", "Arial", sans-serif;
  font-variation-settings: "SKLA" 1000, "TRMG" 750;
}


.box {
      position: relative;
    width: 50px;
    top: -325px;
    width: 5vw;
      left: 79%;
  width: 100px;
  height: 100px;
  background: blueviolet;
  background-color: lightcoral;
}

.boxback {
      position: relative;
    width: 5vw;
      top: -423px;
    left: 0vw;
  width: 100px;
  height: 100px;
  background: blueviolet;
  background-color: lightcoral;
}

#galleryimg {
  width: 500px;
  height: 500px;
}

#motiondivshutters {
      width: 600px;
    height: 500px;
    margin: auto;
    padding: 50px;
    position: relative;
}

@media (max-width: 500px) {
  #container {
        margin-left: 10vw;
    margin-right: 10vw;
  }
  .pricetableheader {
    font-weight: bold;
  }
  .affiliategrid {
    /*position: absolute;*/
    display: grid;
    /*height: 100%;*/
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    background-color: white;
    z-index: 1;
    /*grid-auto-rows: auto; !* overflow size *!*/
    /*grid-auto-flow: row; !* overflow type *!*/
  }
  .myaffimagewalmartsegway {
    width: 60vw;
  }
  .carouselimage {
    height: 190px;
  }
  #editorschoice {
    width: 13vh;
    left: 3vh;
  }
  #overlay{
 font-size: 15px;
    height: 150px;
    overflow: hidden;
  }
  #overlay2 {
    padding-top: 15px;

  }
  #accessorygrid {
    /*position: absolute;*/
    display: grid;
    grid-gap: 10px;

    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    background-color: white;
  }
  #parallaximage {
    position: fixed;
    height: auto;
    width: 70%;
    z-index: -1;
    left: 20%;
    top: 20px;
  }
  #signupmessage {
    font-size: 2vh;
  }
  #magazinegrid {
    display: grid;
    grid-template-columns: 1fr;
    z-index: 1;
    background-color: lightsteelblue;
  }
  #bannertext {
    font-size: 10pt;
  }
  .App-header {
    height: 200px;
  }
  #pricetablegrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
}
  #carouselholder {
    overflow: hidden;
    height: 27vh;
    background-color: white;
  }
  .pagination-bottom span {
    font-size: 2vh;
  }
  #accessorygrid a img {
  width: 70vw;
}
  .gridcontainer {
      padding: 20px;
}
  #galleryimg {
  width: 300px;
  height: 300px;
}
  #motiondivshutters {
      width: 300px;
    height: 300px;
    margin: auto;
    padding: 0px;
    position: relative;
}
  .box {
        top: -74px;
    left: 48%;
  }
  .boxback {
        top: -176px;
    left: 13vw;
  }
}
